export const newsFragments =`fragment NewsArticleFragment on NewsArticle {
  sys {
    id
  }
  title
  subtitle
  content
  location
  pressContact {
    title
    formsLink {
      ...ItemLink
    }
    blockWidth
    contactContent {
      name
      image {
        ...Asset
      }
      jobTitle
      phoneNumber
      email
    }
  }
  linksCollection(limit: 10) {
    items {
      sys {
        id
      }
      linkText
      ctaButtonTracking
      ctaButtonName
      ctaButtonStage
      destination {
        ...UrlSlug
      }
    }
  }
  tagsCollection(limit: 1) {
    items {
      sys {
        id
      }
      tagName
    }
  }
  publicationDate
}

fragment Asset on Asset {
  __typename
  sys {
    id
  }
  title
  description
  contentType
  url
  width
  height
  size
}

fragment AssetLink on AssetLink {
  __typename
  sys {
    id
  }
  asset {
    ...Asset
  }
  linkText
  presentation
}

fragment ItemLink on Entry {
  __typename
  sys {
    id
  }
  ...AssetLink
  ...Link
  ...Url
  ...UrlSlug
  ...AppLink
}

fragment Url on Url {
  __typename
  sys {
    id
  }
  url
  linkText
  description
}

fragment Link on Link {
  __typename
  sys {
    id
  }
  linkText
  ctaButtonTracking
  ctaButtonName
  ctaButtonStage
  callToAction
  description
  destination {
    ...UrlSlug
  }
}

fragment AppLink on AppLink {
  __typename
  sys {
    id
  }
  linkText
  callToAction
  description
  url
}

fragment UrlSlug on UrlSlug {
  __typename
  sys {
    id
  }
  slug
  disableIndexing
  theme
}

fragment NewsArticleFragment on NewsArticle {
  sys {
    id
  }
  title
  subtitle
  content
  location
  pressContact {
    title
    formsLink {
      ...ItemLink
    }
    blockWidth
    contactContent {
      name
      image {
        ...Asset
      }
      jobTitle
      phoneNumber
      email
    }
  }
  linksCollection(limit: 10) {
    items {
      sys {
        id
      }
      linkText
      ctaButtonTracking
      ctaButtonName
      ctaButtonStage
      destination {
        ...UrlSlug
      }
    }
  }
  tagsCollection(limit: 1) {
    items {
      sys {
        id
      }
      tagName
    }
  }
  publicationDate
}
`