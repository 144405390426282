import { useEffect } from 'react'
// eslint-disable-next-line import/no-unresolved
import { Router } from '@reach/router'
import { navigate } from 'gatsby'
import { Spinner } from '@dfds-ui/react-components'
import { useQuery, gql } from '@apollo/client'
import { newsFragments } from '../__generated__/news-article-fragments'
import { usePageTrackingData } from '../tracking'
import { track } from '@dfds-frontend/tracking'
import NewsArticle from '../templates/NewsArticle'

const DYNAMIC_QUERY = gql`
  query NewsArticle_Query($slug: String!) {
    urlSlugCollection(limit: 1, where: { slug: $slug }) {
      items {
        page {
          ...NewsArticleFragment
        }
      }
    }
  }
  ${newsFragments}
`

const ExplicitlyTrackedNewsArticle = ({ data }) => {
  const { getPageTrackingData } = usePageTrackingData()
  const page = data?.content?.page
  // eslint-disable-next-line no-unused-vars
  const { bookingEngine, currentLocale, ...rest } = getPageTrackingData()
  const trackingData = {
    ...rest,
    event: 'vpv',
    bookingEngine: bookingEngine?.toLowerCase(),
    businessArea: 'group',
    contentfulId: page?.sys?.id,
    pageTitle: page?.title,
  }
  track(trackingData)

  return <NewsArticle data={data} />
}

const DynamicNewsArticle = ({ slug }) => {
  const { data, error, loading } = useQuery(DYNAMIC_QUERY, {
    variables: { slug: `about/media/news/${slug}` },
  })
  const content = data?.urlSlugCollection?.items?.[0]
  const title = content?.page?.title
  useEffect(() => {
    if (title) {
      document.title = title
      setTimeout(() => (document.title = title), 1000)
    }
  }, [title])
  if (loading) return <Spinner />
  if (error || !content) {
    navigate(`/en/about/media/news_/${slug}`) // trigger 404
    window.history.replaceState(null, '', `/en/about/media/news/${slug}`) // reset url to current url
    return null
  }
  return <ExplicitlyTrackedNewsArticle data={{ content }} />
}

const DynamicNewsPage = () => {
  return (
    <Router>
      <DynamicNewsArticle path="/en/about/media/news/:slug" />
    </Router>
  )
}

export default DynamicNewsPage
